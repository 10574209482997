import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AccordionExpandDefault() {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#c48252" }}
          >
            What is Horsgo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            "Horsgo" is a racetrack platform based on blockchain technology.
            Users can experience fast transactions, fairness, and other
            technical benefits through blockchain system and ownership.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#c48252" }}
          >
            How to get $horsgo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can buy on a solana-based DEX like Raydium, Orca, or Jupiter.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#c48252" }}
          >
            What is Stadiums?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Every racing will take place at the Stadium. Each Stadium's
            ownership is established with 100 NFT stadium tokens.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#c48252" }}
          >
            What can I trade on the Horsgo market?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can buy or sell racehorses or stadiums on the Horsgo Market
            using $horsgo tokens
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
