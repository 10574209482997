import React, { useEffect, useRef } from "react";
import "./HowToBuy.css";

declare global {
  interface Window {
    Jupiter: any;
  }
}

export default function HowToBuy() {
  const homeRef = useRef<HTMLDivElement>(null);
  const jupiterRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);
  const rightRef_2 = useRef<HTMLHeadingElement>(null);
  const rightRef_3 = useRef<HTMLHeadingElement>(null);
  const rightRef_4 = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBottom");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          openJupiter();
          observer3.unobserve(entry.target);
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    if (rightRef_2.current) {
      observer2.observe(rightRef_2.current);
    }

    if (rightRef_3.current) {
      observer2.observe(rightRef_3.current);
    }

    if (rightRef_4.current) {
      observer2.observe(rightRef_4.current);
    }

    if (jupiterRef.current) {
      observer3.observe(jupiterRef.current);
    }
    return () => {
      observer.disconnect();
      observer2.disconnect();
      observer3.disconnect();
    };
  }, []);

  function openJupiter() {
    window?.Jupiter.init({
      displayMode: "integrated",
      integratedTargetId: "integrated-terminal",
      endpoint:
        "https://solana-mainnet.g.alchemy.com/v2/-9phlXKbXl2HHbIjlu32SZ1ZvJuWV7Vq",
      strictTokenList: false,
      formProps: {
        fixedOutputMint: true,
        initialInputMint: "So11111111111111111111111111111111111111112",
        initialOutputMint: "B5w3u24QEeNbhAD9pP8hxVSgPwynUZW3B2XyQapV9WUN",
      },
    });
  }
  return (
    <div className="HowToBuy" id="howtobuy">
      <div className="HowToBuy-container">
        <h1 className="About-title gradient-text titlemodel" ref={homeRef}>
          How to buy
        </h1>
        <div
          id="integrated-terminal"
          className="HowToBuy-jup"
          ref={jupiterRef}
        ></div>
        <h1 style={{ textAlign: "center" }}>or</h1>
        <div className="HowToBuy-small-container" ref={rightRef}>
          <img
            src="/images/Phantom-Icon_Circle_512x512.png"
            alt="Phantom-Logo-Purple"
            className="HowToBuy-img"
          />
          <div>
            <h2 className="HowToBuy-subtitle">Create Wallet</h2>
            <p className="HowToBuy-text">
              You need a Solana wallet to store your $horsgo. We recommend using
              Phantom or Sollet.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_2}>
          <img
            src="/images/solanaLogoMark.svg"
            alt="solanaLogoMark"
            className="HowToBuy-img"
          />
          <div>
            <h2 className="HowToBuy-subtitle">Get some $SOL</h2>
            <p className="HowToBuy-text">
              You need $SOL to pay for transactions on the Solana blockchain.
              You can buy $SOL on Binance or FTX.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_3}>
          <img
            src="/images/jupiter-logo.svg"
            alt="swaps"
            className="HowToBuy-img"
          />
          <div>
            <h2 className="HowToBuy-subtitle">Go to Jupiter or Raydium</h2>
            <p className="HowToBuy-text">
              Go to Jupiter or Raydium and connect your wallet.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_4}>
          <img src="/images/symbol.png" alt="swaps" className="HowToBuy-img" />
          <div>
            <h2 className="HowToBuy-subtitle">Swap $SOL for $horsgo</h2>
            <p className="HowToBuy-text">
              Swap your $SOL for $horsgo on Jupiter or Raydium.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
