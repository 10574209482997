import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

interface TextAnimationProps {
  text: string;
  textStyle?: React.CSSProperties;
  durationTime: number;
  onComplete?: () => void;
  className?: string;
}

const TextAnimation: React.FC<TextAnimationProps> = ({
  text,
  textStyle,
  onComplete,
  durationTime,
  className,
}) => {
  const textRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const tl = gsap.timeline({ paused: true });

    if (textRef.current) {
      tl.fromTo(
        textRef.current,
        { text: "" },
        {
          duration: durationTime,
          text: text,
          type: "revealed",
          ease: "none",
          onStart: () => {
            if (textRef.current) {
              textRef.current.style.opacity = "1";
            }
          },
        }
      )
        .fromTo(
          textRef.current,
          { y: "100%", rotationX: -90 },
          {
            y: 0,
            rotationX: 0,
            duration: durationTime,
            ease: "power2.out",
            stagger: 0.05,
          },
          "<"
        )
        .eventCallback("onComplete", onComplete || null);

      tl.play();
    }
  }, [text, onComplete, durationTime]);

  return (
    <h1
      ref={textRef}
      style={{
        margin: 0,
        opacity: 0,
        ...textStyle,
      }}
      className={className}
    >
      {" "}
    </h1>
  );
};

export default TextAnimation;
