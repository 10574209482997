import React from "react";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="Contact" id="contact">
      <h1 className="Contact-title">Contact</h1>
      <div className="Contact-container">
        <div className="grid-container">
          <form>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "15px",
                marginBottom: "15px",
              }}
              className="mobile-col"
            >
              <input type="text" id="name" name="name" placeholder="Name" />

              <input type="email" id="email" name="email" placeholder="Email" />
            </div>

            <textarea id="message" name="message" placeholder="Message" />

            <button type="submit" className="Contact-button">
              Send
            </button>
          </form>
        </div>
      </div>
      <img src="/images/contact.png" alt="contact" className="Contact-image" />
      <footer className="Contact-footer">
        <div className="footer-links">
          <div className="footer-category">
            <h3>Community</h3>
            <ul>
              <li>
                <a
                  href="https://discord.com/invite/zVPjhCFG"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/r_kakui777"
                  target="_blank"
                  rel="noreferrer"
                >
                  X
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/horsgo12"
                  target="_blank"
                  rel="noreferrer"
                >
                  Telegram
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-category">
            <h3>Documentation</h3>
            <ul>
              <li>
                <a
                  href="https://document.horsgo.com/horsgo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Whitepaper
                </a>
              </li>
            </ul>
          </div>
        </div>
        &copy; 2024 Horsgo. All rights reserved.
      </footer>
    </div>
  );
}
