import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Home from "../../components/Home/Home";
import About from "../../components/About/About";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import Community from "../../components/Community/Community";
import Roadmap from "../../components/Roadmap/Roadmap";
import FAQ from "../../components/Faq/Faq";
import Contact from "../../components/Contact/Contact";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./Main.css";
import HowToBuy from "../../components/ToBuy/HowToBuy";

function Main() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const createSnowflake = () => {
      const snowflake = new Image();
      snowflake.className = "snowflake";
      snowflake.src = "/images/golden-token.png";
      snowflake.style.position = "fixed";
      snowflake.style.width = "50px";
      snowflake.style.height = "50px";
      snowflake.style.left = `${Math.random() * window.innerWidth}px`;
      snowflake.style.animationDuration = `${Math.random() * 5 + 5}s`;
      snowflake.style.zIndex = "1";

      const isReverse = Math.random() < 0.5;
      snowflake.style.animationName = isReverse ? "fallReverse" : "fall";
      document.body.appendChild(snowflake);

      setTimeout(() => {
        snowflake.remove();
      }, Math.random() * 10000 + 5000);
    };

    if (window.innerWidth > 768) {
      const interval = setInterval(createSnowflake, 500);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="Main" id="Main">
      <Header />
      <Home id="home" />
      <About id="about" />
      <HowToBuy />
      <Roadmap />
      <Tokenomics id="tokenomics" />
      <Community />
      <FAQ />
      <Contact />
      {showScrollButton && (
        <div
          className="scroll-to-top"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <KeyboardArrowUpIcon />
        </div>
      )}
    </div>
  );
}

export default Main;
