import React, { useState } from "react";
import "./Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { HashLink } from "react-router-hash-link";

const navItems = [
  { name: "HOME", path: "#home", key: "home" },
  { name: "ABOUT", path: "#about", key: "about" },
  { name: "HOW TO BUY", path: "#howtobuy", key: "howtobuy" },
  { name: "ROADMAP", path: "#roadmap", key: "roadmap" },
  { name: "TOKEN", path: "#tokenomics", key: "token" },
  { name: "COMMUNITY", path: "#community", key: "community" },
  { name: "FAQ", path: "#faq", key: "faq" },
  { name: "CONTACT", path: "#contact", key: "contact" },
];

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="Header">
      <div className="Header-navbar-start">
        <HashLink smooth to={"#home"}>
          <img
            src="/images/symbol.png"
            width={48}
            alt="logo"
            style={{ margin: "0 1rem" }}
          />
        </HashLink>

        {window.innerWidth > 1000 && (
          <div className="Header-navbar-Link">
            {navItems.map((item, index) => (
              <HashLink
                key={item.key}
                smooth
                to={item.path}
                className="Header-navbar-item"
              >
                {item.name}
              </HashLink>
            ))}
          </div>
        )}
      </div>
      <div className="Header-navbar-end">
        <span className="Header-navbar-btn">
          <HashLink
            key="buy-now"
            smooth
            to={"#integrated-terminal"}
            className="Header-navbar-item"
          >
            BUY NOW
          </HashLink>
        </span>
        {window.innerWidth < 1000 && (
          <div className="Header-Menu" onClick={toggleMenu}>
            <MenuIcon
              style={{ color: "black", width: "50px", height: "50px" }}
            />
          </div>
        )}
      </div>

      <Dialog open={isMenuOpen} onClose={toggleMenu} fullWidth maxWidth="xs">
        <DialogContent>
          {navItems.map((item, index) => (
            <div style={{ padding: "0.5rem", fontSize: "20px" }} key={item.key}>
              <HashLink
                key={item.key}
                smooth
                to={item.path}
                className="Header-menu-item"
                onClick={toggleMenu}
              >
                {item.name}
              </HashLink>
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </header>
  );
}
