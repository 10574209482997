import AccordionExpandDefault from "./Accordion";
import "./Faq.css";

export default function FAQ() {
  return (
    <div className="FAQ" id="faq">
      <div className="FAQ-container">
        <h1 className="About-title titlemodel">FAQ</h1>
        <AccordionExpandDefault />
      </div>
    </div>
  );
}
