import React, { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextAnimation from "./TextAnimation";
import "./Home.css";

type HomeProps = {
  id: string;
};

export default function Home({ id }: HomeProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const secondHeadingRef = useRef<HTMLHeadingElement>(null);
  const [ca, setCA] = useState("");

  const text = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState("52px");

  const handleImageClick = () => {
    const aboutElement = document.getElementById("about");
    if (aboutElement) {
      aboutElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1100) {
        setFontSize("52px");
      } else {
        setFontSize("52px");
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    setCA("B5w3u24QEeNbhAD9pP8hxVSgPwynUZW3B2XyQapV9WUN");

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (secondHeadingRef.current) {
      observer2.observe(secondHeadingRef.current);
    }

    return () => {
      observer.disconnect();
      observer2.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="Home" id={id}>
      <div className="bg"></div>
      <div ref={text} className="Home-container">
        <TextAnimation
          text="HORSGO"
          durationTime={1}
          textStyle={{ fontSize: fontSize, textAlign: "center" }}
        />
        <div
          ref={secondHeadingRef}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px 0",
            width: "fit-content",
          }}
        >
          <img
            src="/images/horsgo_10.png"
            alt="home"
            className="Home-image"
            onClick={handleImageClick}
          />
        </div>
        <TextAnimation
          text="Enter a world where blockchain and racehorses coexist with Horsgo!"
          durationTime={5}
          textStyle={{
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "500",
            marginTop: "1rem",
            color: "#000",
            minHeight: "44px",
          }}
        />

        <div className="Home-iconbtns">
          <a
            href="https://www.dextools.io/app/en/solana/pair-explorer/46ZvABPLmN3ZG6nH3HWbqF2kaYVXSk1qk8JXhQzXTgMT?t=1717491067373"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/dextools.svg"
              alt="dextools"
              width={38}
              height={38}
            />
          </a>
          <a
            href="https://dexscreener.com/solana/46zvabplmn3zg6nh3hwbqf2kayvxsk1qk8jxhqzxtgmt"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/dex-screener-seeklogo.png"
              alt="dexscreener"
              width={38}
              height={38}
            />
          </a>
          <a href="https://t.me/horsgo12" target="_blank" rel="noreferrer">
            <img
              src="/images/telegram.svg"
              width={38}
              height={38}
              alt="telegram"
            />
          </a>
          <a
            href="https://twitter.com/r_kakui777"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/x-logo.svg"
              alt="twitter"
              width={38}
              height={38}
            />
          </a>
          <a
            href="https://discord.com/invite/zVPjhCFG"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/discord-black.svg"
              alt="discord"
              width={38}
              height={38}
            />
          </a>
        </div>
        <div className="flex-container flex-direction-col flex-start justify-center gap-20">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="mobile-column"
          >
            <span
              style={{
                fontWeight: 500,
                margin: 0,
                fontSize: "14px",
                overflowWrap: "anywhere",
              }}
            >
              {ca}
            </span>
            <div style={{ display: "flex" }}>
              <button className="copy-btn" onClick={handleCopyToClipboard}>
                <ContentCopyIcon style={{ color: "black", width: "14px" }} />
              </button>
              {showPopup && (
                <div className="popup">
                  <p
                    style={{
                      margin: "0",
                      fontSize: "16px",
                      position: "absolute",
                    }}
                  >
                    Copied
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
