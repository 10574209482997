import React, { useRef, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "./Roadmap.css";

interface RoadmapItem {
  id: number;
  title: string;
  description: string;
}

const roadmapItems: RoadmapItem[] = [
  {
    id: 1,
    title: "Phase1",
    description:
      "Horsgo Game Planning \n Opening official Twitter account \n Opening a Telegram channel \n Opening Discord channel",
  },
  {
    id: 2,
    title: "Phase2",
    description:
      "Dex listing \n Twitter +1k \n Telegram +1k \n Discord +1k \n Twitter & Telegram Shilling \n Dextools Update \n Dexscreener update \nTelegram Trading \n CoinGecko Listing \n CMC Listing \n Influencer partnership \n YouTube Advertising",
  },
  {
    id: 3,
    title: "Phase3",
    description:
      "Official NFT Mint \n Launching a horse breeding \n NFT airdrop for TOP 100 Holders",
  },
  {
    id: 4,
    title: "Phase4",
    description:
      "CEX listing \n Horse Racing game Beta released \n Open Horsgo Market \n 3D Horse Racing game officially launched",
  },
];

const Roadmap = () => {
  const roadmapRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [scrollY, setScrollY] = useState(0);
  const roadmapSectionRef = useRef<HTMLDivElement | null>(null);
  const roadmapLineRef = useRef<HTMLDivElement | null>(null);
  const roadmapLineColoredRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    roadmapRefs.current = roadmapRefs.current.slice(0, roadmapItems.length);

    const handleScroll = () => {
      if (
        roadmapSectionRef.current &&
        roadmapLineRef.current &&
        roadmapLineColoredRef.current
      ) {
        const lineRect = roadmapLineRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const lineTop = lineRect.top + window.scrollY;
        const lineBottom = lineRect.bottom + window.scrollY;
        const scrollPos = window.scrollY + windowHeight / 2;

        let newScrollY = scrollPos - lineTop;

        if (scrollPos < lineTop) {
          newScrollY = 200;
        } else if (scrollPos > lineBottom) {
          newScrollY = lineBottom - lineTop + 200;
        }

        const percentageScrolled =
          ((newScrollY - 200) / (lineBottom - lineTop)) * 100;
        roadmapLineColoredRef.current.style.height = `${percentageScrolled}%`;

        setScrollY(newScrollY);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="roadmap" id="roadmap" ref={roadmapSectionRef}>
      <h1 className="About-title titlemodel">Roadmap</h1>
      <div className="roadmap-line" ref={roadmapLineRef}>
        <div className="roadmap-line-colored" ref={roadmapLineColoredRef}></div>
      </div>
      <img
        src="/images/horse-sprite.gif"
        alt="Horse"
        className="scrolling-horse"
        style={{ top: `${scrollY}px` }}
      />
      {roadmapItems.map((item, index) => (
        <RoadmapItems
          key={item.id}
          item={item}
          index={index}
          roadmapRefs={roadmapRefs}
        />
      ))}
    </div>
  );
};

interface RoadmapItemProps {
  item: RoadmapItem;
  index: number;
  roadmapRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
}

const RoadmapItems: React.FC<RoadmapItemProps> = ({
  item,
  index,
  roadmapRefs,
}) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  useEffect(() => {
    if (inView) {
      const itemRef = roadmapRefs.current[index];
      if (itemRef) {
        itemRef.classList.remove("left-start", "right-start");
        itemRef.classList.add("slide-in");
      }
    }
  }, [inView, index, roadmapRefs]);

  return (
    <div
      ref={(el) => (roadmapRefs.current[index] = el)}
      className={`roadmap-item ${
        index % 2 === 0 ? "left-start" : "right-start"
      }`}
    >
      <div ref={inViewRef} className="roadmap-item-content">
        <h2>{item.title}</h2>
        {item.description.split("\n").map((line, idx) => (
          <p key={idx}>{line}</p>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
