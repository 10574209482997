import React, { useEffect, useRef } from "react";
import TextAnimation from "../Home/TextAnimation";
import Slider from "react-slick";
import { HashLink } from "react-router-hash-link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./About.css";

type AboutProps = {
  id: string;
};

const FeedData = [
  {
    id: 1,
    image: "/images/ownership.png",
    content:
      "You can become the owner of a horse on an online horse racing platform",
    title: "Ownership",
    link: "https://document.horsgo.com/horsgo#slow-digitalization-of-the-horse-racing-market",
  },
  {
    id: 2,
    image: "/images/membership.png",
    title: "Membership",
    content: "Get access to Horsgo's amazing membership benefits",
    link: "https://document.horsgo.com/horsgo#slow-digitalization-of-the-horse-racing-market",
  },
  {
    id: 3,
    image: "/images/tradeable.png",
    title: "Tradeable",
    content:
      "You can sell your own online racehorses on the on our own marketplace",
    link: "https://document.horsgo.com/horsgo#slow-digitalization-of-the-horse-racing-market",
  },
  {
    id: 4,
    image: "/images/solanaLogoMark.svg",
    content:
      "Horsgo is powered by the Solana network to ensure fast transactions and smooth gameplay",
    title: "Fast Transaction",
    link: "https://document.horsgo.com/horsgo#slow-digitalization-of-the-horse-racing-market",
  },
];

export default function About({ id }: AboutProps) {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const rightRef_2 = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLHeadingElement>(null);
  const bottomRef_2 = useRef<HTMLDivElement>(null);
  const bottomRef_3 = useRef<HTMLDivElement>(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [],
    autoplay: true,
    autoplaySpeed: 4000,
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBottom");
          observer3.unobserve(entry.target);
        }
      });
    }, options);

    if (leftRef.current) {
      observer.observe(leftRef.current);
    }
    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }
    if (rightRef_2.current) {
      observer2.observe(rightRef_2.current);
    }
    if (bottomRef.current) {
      observer3.observe(bottomRef.current);
    }
    if (bottomRef_2.current) {
      observer3.observe(bottomRef_2.current);
    }
    if (bottomRef_3.current) {
      observer3.observe(bottomRef_3.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="About" id={id}>
      <div className="About-container">
        <div className="frame-wide">
          <img src="/images/frame-1.png" alt="frame1" />
          <img src="/images/frame-2.png" alt="frame1" />
          <img src="/images/frame-3.png" alt="frame1" />
          <img src="/images/frame-4.png" alt="frame1" />
        </div>
        <div
          className="opacity"
          ref={leftRef}
          style={{
            height: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "400px", height: "auto", gap: "20px" }}>
            <h1 className="About-title titlemodel">About HORSGO</h1>
            <TextAnimation
              text="Horsgo will develop various businesses related to horses, including
            online 3D horse racing & breeding platform and offline racehorse
            breeding. Join Horsgo's fresh and dynamic blockchain world where
            cryptocurrency and racehorses coexist."
              durationTime={2}
              textStyle={{
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "1.5em",
                color: "#888888",
              }}
              className="About-content"
            />
            <div className="About-button-box">
              <HashLink smooth to={"#howtobuy"}>
                Get Started
              </HashLink>
              <a
                href="https://document.horsgo.com/horsgo"
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div
          className="opacity align-center"
          ref={rightRef}
          style={{ margin: "0 2rem" }}
        >
          <img src="/images/horsgo_7.png" className="About-image" alt="horse" />
        </div>
      </div>
      <div
        style={{
          height: "90vh",
          width: "100%",
        }}
        className="opacity"
        ref={bottomRef}
      >
        <h1 className="About-title gradient-text titlemodel">
          Why choose HORSGO?
        </h1>
        <Slider {...settings}>
          {FeedData.map((item) => (
            <div key={item.id} className="carousel-item">
              <div
                style={{ display: "flex", gap: "15px", padding: "1rem" }}
                className="auto-col"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "470px",
                  }}
                  className="auto-width"
                >
                  <div className="carousel-title">{item.title}</div>
                  <div className="carousel-content">{item.content}</div>
                  <a
                    className="carousel-link"
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Learn More</span>
                  </a>
                </div>

                <img
                  src={item.image}
                  alt={item.title}
                  width={250}
                  height={250}
                  className="carousel-image"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div
        className="opacity"
        style={{ margin: "0", gap: "2rem", height: "100vh", width: "auto" }}
        ref={bottomRef_2}
      >
        <h1 className="About-title titlemodel">About HORSGO NFT</h1>
        <div>
          <div style={{ display: "flex" }} className="About-mobile">
            <div style={{ width: "400px" }}>
              <TextAnimation
                text="Horsgo NFTs are broadly categorized into racehorses, stadiums, and items. You can breed your own racehorses or play matches in your own stadium. NFTs and $horsgo tokens are 100% owned by their owners, giving you full control over your assets."
                durationTime={2}
                textStyle={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "1.5em",
                  color: "#888888",
                }}
                className="About-content"
              />
              <div className="About-button-box">
                <a
                  href="https://document.horsgo.com/horsgo/horsgo-game/horse-racing/horse"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="align-center">
              <img src="/images/nfts.png" className="About-image" alt="nfts" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
